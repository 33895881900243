// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-screenie-index-js": () => import("./../../../src/pages/Screenie/index.js" /* webpackChunkName: "component---src-pages-screenie-index-js" */),
  "component---src-pages-screenie-press-kit-js": () => import("./../../../src/pages/Screenie/pressKit.js" /* webpackChunkName: "component---src-pages-screenie-press-kit-js" */),
  "component---src-pages-textla-index-js": () => import("./../../../src/pages/textla/index.js" /* webpackChunkName: "component---src-pages-textla-index-js" */),
  "component---src-pages-textla-message-preview-1-js": () => import("./../../../src/pages/textla/messagePreview1.js" /* webpackChunkName: "component---src-pages-textla-message-preview-1-js" */),
  "component---src-pages-textla-message-preview-2-js": () => import("./../../../src/pages/textla/messagePreview2.js" /* webpackChunkName: "component---src-pages-textla-message-preview-2-js" */),
  "component---src-pages-textla-panel-1-js": () => import("./../../../src/pages/textla/panel1.js" /* webpackChunkName: "component---src-pages-textla-panel-1-js" */),
  "component---src-pages-textla-panel-2-js": () => import("./../../../src/pages/textla/panel2.js" /* webpackChunkName: "component---src-pages-textla-panel-2-js" */),
  "component---src-templates-quickres-tutorials-change-resolution-macos-js": () => import("./../../../src/templates/quickres-tutorials-change-resolution-macos.js" /* webpackChunkName: "component---src-templates-quickres-tutorials-change-resolution-macos-js" */)
}

